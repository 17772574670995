import React, { useEffect, useState } from "react";
import {collection, getDocs, limit, orderBy, query} from "firebase/firestore";

import Footer from "./Footer";
import Header from "./Header";
import {Helmet} from "react-helmet";
import { Icon } from '@iconify/react';
import {db} from "./firebase-config";

// ANIMALS OBJECTS

const trees = {
    "names" : ["an apple tree", "a pear tree", "a peach tree", "a banyan tree", "a fig tree", "a neem tree", "a quaking aspen tree", "a beech tree", "a black birch tree", "a paper birch tree", "a butternut tree", "a black cherry tree", "a japanese cherry tree", "a pin cherry tree", "a chestnut tree", "a cucumber tree", "a mountain magnolia tree", "a purple magnolia tree", "an american elm tree", "an english elm tree", "a black locust tree", "an hickory tree", "a tamarack tree", "a western larch tree", "a maple tree", "a red maple tree", "a sugar maple tree", "a chestnut oak tree", "a northern red oak tree", "a scarlet oak tree", "a white oak tree", "an eastern white pine tree", "a serviceberry tree", "a black walnut tree", "a black willow tree", "a flowering dogwood tree", "an australian mountain ash tree", "an eucalyptus", "a flowering gum tree", "a fir tree", "an hemlock tree", "a red pine tree", "The bristlecone pine tree", "a spruce tree", "a sequoia", "a kauri tree", "a nootka cypress tree", "an atlas cedar tree", "a virginia live oak", "a coconut palm tree", "a kumquat tree", "an olive tree", "the Major Oak of Sherwood Forest", "the Arbol del Tule", "a baobab", "the Boab Prison Tree", "a Socotra dragon tree", "The General Sherman tree", "the Chêne Chappelle", "the La-Haye-de-Routot Yews tree"],
    "adj" : ["during winter", "without leaf", "in fire", "during spring", "during autumn", "during summer", "under snow", "in giant", "very small", "with a face", "hit by lightning", "inhabited by elves", "with leaves of an unusual color", "with a trunk with a weird shape", "dying", "on a rock", "surrounded by fireflies"]
};

const grass = {
    "names" : ["a bamboo", "dichondra", "zoysia grass", "grass", "pampas grass", "monkey grass", "pink muhly grass", "timothy grass", "mexican feather grass", "japanese forest grass", "blue eyed grass", "maiden grass", "bear grass", "elephant grass", "japanese blood grass", "american tree moss", "common haircap moss", "springly turf moss", "Pinchushion moss", "mood moss", "plume moss", "water screw moss", "Tamarisk moss", "kelp", "seaweed"],
    "adj" : ["dry", "after the morning dew", "in giant", "wet", "in plastic", "under the snow", "burning", "colorful", "with an unusual color", "moving", "underwater", "on an other planet", "inhabited by fairies", "inhabited by ants"]
};

const flowers = {
    "names" : ["a rose", "a wisteria", "a tulipa", "an aloe verae flower", "an asiatic Lily", "a balsam", "a blue morning glory", "a blue water lily", "a bluestar flower", "a raflesia", "a butterfly pea", "a chamomile", "a chrysanthemum", "a common crape myrtle", "a common white frangipani", "a daffodil", "a dahlia", "a daisy", "a delonix regia", "a flax", "a forest ghost flower", "a glory lily", "a hibiscus", "a hollyhock", "an indian tulip", "a zombi pea", "a mountain laurel", "a foxtail orchid", "a lavender", "a lilac", "a lotus", "a magnolia", "an orchid", "a pandanus", "a periwinkle", "a primrose", "a purple passion flower", "a rose", "a shameplant", "a star glory", "a sunflower", "a tulip", "a sweet violet"],
    "adj" : ["dry", "after the morning dew", "in giant", "wet", "in plastic", "under the snow", "burning", "colorful", "with an unusual color", "moving", "underwater", "inhabited by fairies", "with a face", "blooming", "with leaves of an unusual color", "in a cute pot", "in a chinese pot"]
};

const plants = {
    "names" : ["a carnivorous plant", "a bamboo", "an eucalyptus", "a fiddle-leaf fig", "a philodendron", "a snake plant", "a split-leaf philodendron", "an african mask", "an agave", "an alocasia", "an areca palm", "an aloe vera", "a ball cactus", "a baby tears", "a caladium", "an echeveria", "a gasteria", "a heartleaf philodendron", "an imperial red philodendron", "a jade plant", "a japanese fern tree", "a jewel aloe", "a kentia palm", "a maidenhair fern", "a norfolk island pine", "a panda plant", "a rattlesnake plant", "a spiderwort", "a spiral aloe", "a swiss cheese plant", "a watermelon peperomia", "a zebra plant"],
    "adj" : ["dry", "after the morning dew", "in giant", "wet", "in plastic", "under the snow", "burning", "colorful", "with an unusual color", "moving", "underwater", "inhabited by fairies", "in small", "burning", "shiny", "in a cute pot", "in a chinese pot"]
}

const fruitsVegetables = {
    "names" : ["a carrot", "a broccoli", "a cauliflower", "corn", "a cucumber", "an eggplant", "a red pepper", "lettuce", "a potato", "a pumpkin", "garlic", "a tomato", "a beetroot", "peas", "a zucchini", "a radish", "a sweet potato", "an artichoke", "cabbage", "a chilli", "lentil", "a quince", "a lime", "a swede", "an onion", "a chestnut", "an apple", "a pear", "a banana", "an apricots", "an avocados", "blueberries", "a clementine", "dates", "a dragon fruit", "a grape", "a guava", "a kiwi", "a lychee", "a melon", "an olive", "a papaya", "a passion fruit", "a watermelon"],
    "adj" : ["cooked", "sliced", "in fire", "fried", "in a meal you'd like", "looking like meat", "in a soup", "in a smoothie", "rotten", "shiny", "in a dessert", "in a starter", "in a jar", "in a cake", "eaten by an animal"]
};


const vegetal = {
    "trees" : trees,
    "grass" : grass,
    "flowers" : flowers,
    "plants" : plants,
    "fruits and vegetables" : fruitsVegetables
}

// ANIMAL OBJECTS

const earthAnimals = {
    "names" : ["a dog", "a cat", "a mouse", "a rat", "a lizard", "a tiger", "a wolf", "a duck", "an elephant", "a lion", "a cheetah", "a fox", "a snow leopard", "a kangaroo", "an antelope", "a buffalo", "a rhinoceros", "a pyhton", "a rat", "a meerkat", "a zebra", "a caracal", "a giraffe", "a boar", "a hyena", "a gorilla", "an hippopotamus", "a mongoose", "a warthog", "a chimpanzee", "a baboon", "a monkey", "a lemur", "an impala", "an ostrich", "an elk", "a bison", "a polar bear", "a moose", "a grizzly bear", "a goat", "a capybara", "a jaguar", "a sloth", "an anteater", "a tapir", "a llama", "an alpaca", "a wolf", "a deer", "an orang-utan", "a monitor lizard", "an iguana", "a slow loris", "a panda", "a red panda", "a cobra", "a snakerattle", "a koala", "a honey badger", "a koala", "a casoar", "a hedgehog", "a pig", "a donkey", "a horse", "a dodo", "a mammoth", "a tortoise", "a kiwi", "a quokka", "a hamster", "a guinea pig", "a pangolin", "an armadillo"],
    "adj" : ["baby one", "in cute", "chibi", "playing video games", "playing", "happy", "skeleton", "tall", "small", "giant", "flying", "swimming", "with an unusual color", "with unusual skin patterns", "with a pokemon type", "fluffy", "as a pet", "with scales", "with an armor", "zombi", "with feathers", "the alpha one"]
}

const waterAnimals = {
    "names" : ["an otter", "a frog", "a toad", "a salamander","a swordfish", "a whale", "a dolphin", "a clownfish", "a fish", "a seal", "an octopus", "a shark", "a starfish", "a penguin", "a jellyfish", "a squid", "a seahorse", "a walrus", "a clams", "an oyster", "a sea urchin", "a sea anemone", "coral", "a turtle", "a piranha", "a platypus", "a tuna", "an eal", "a salmon", "a dragonet", "an elephant seal", "a fugu", "a lionfish", "a manta ray", "a stingray", "an anaconda", "a giant squid"],
    "adj" : ["baby one", "in cute", "chibi", "playing video games", "playing", "happy", "skeleton", "tall", "small", "giant", "flying", "swimming", "with an unusual color", "with unusual skin patterns", "with a pokemon type", "fluffy", "as a pet", "zombi", "with an armor", "with feathers", "the alpha one"]
}

const flyingAnimals = {
    "names" : ["a bat", "an eagle", "a hummingbird", "a flying squirrel", "an albatross", "a seagull", "a pelican", "a shoebill", "a scarlet ibis", "a goose", "a condor", "a peafowl", "a hoatzin", "a whooping crane", "a kakapo", "a crow", "an owl", "a woodpecker", "a sparrow", "a pigeon", "a parrot"],
    "adj" : ["baby one", "in cute", "chibi", "playing video games", "playing", "happy", "skeleton", "tall", "small", "giant", "flying", "swimming", "with an unusual color", "with unusual skin patterns", "with a pokemon type", "fluffy", "as a pet", "zombi", "with scales", "the alpha one", "with an armor"]
}

const arthropods = {
    "names" : ["a spider", "a stickbug", "a beetle", "a leafbug", "a crockroach", "a grasshopper", "a butterfly", "a moth", "a cicada", "a centipede", "a scutigeria", "a tarentula", "a crab", "a crayfish", "a lobster", "a woodlouse", "a mantis", "a dragonfly", "a scorpion", "a solifugae", "an amblypyge", "a fly", "a hornet", "a bee", "a wasp", "a ladybug", "a snail", "a slug", "a termite", "an ant", "an earthworm", "a bumblebee", "a cricket", "a locust", "a mosquito", "a bedbug"],
    "adj" : ["in big", "in small", "flying", "in cute", "chibi", "happy", "with clothes", "drinking coffee", "cooking", "boxing", "playing chess", "playing football", "playing basketball", "driving", "drawing", "eating an other insect", "dancing with an other insect", "studying", "at hospital", "driving", "at school", "smoking", "badass", "in an unusual color"]
}

const creatures = {
    "names" : ["a centaur", "a mandragora", "a snowman", "a xenomorph", "a basilik", "Beelzebub", "Bigfoot", "a blob", "a leprechaun", "a chimera", "a chupacabra", "a creeper", "a zombie", "a cyclop", "Dracula", "a dragon", "a drake", "Frankenstein", "a gnome", "a god", "a goblin", "Godzilla", "a gremlin", "a hydra", "an incubus", "a succubus", "Pennywise", "the Kraken", "the Leviathan", "the Loch Ness Monster", "Medusa", "a minotaur", "a mummy", "a phoenix", "an orc", "a sandworm", "a siren", "a skeleton", "Slender man", "an ender man", "a troll", "an ogre", "a werewold", "a yeti", "a zombie", "a ghost"],
    "adj" : ["zombified", "with an armor", "with the weapon you want", "eating burger", "drinking a smoothie", "baby one", "with a cute smile", "with a dumb looking", "playing video games", "fighting", "cooking", "drunk", "sleeping", "whith its family", "dancing"]
}

const animals = {
    "earth animals" : earthAnimals,
    "water animals" : waterAnimals,
    "flying animals" : flyingAnimals,
    "arthropods" : arthropods,
    "creatures" : creatures
}



// SOMEONE OBJECTS


const ficCharacters = {
    "names" : ["Superman", "Mickey Mouse", "James Bond", "Bugs Bunny", "Batman", "Darth Vader", "Peter Pan", "Rocky Balboa", "Han Solo", "Homer Simpson", "Spiderman", "Barbie", "Spock", "The Joker", "Tarzan", "Popeye", "Jon Snow", "Marty McFly", "Kermit The Frog", "E.T", "Yoda", "Zorro", "Gandalf", "The Grinch", "The Hulk", "your favorite hero", "Pikachu", "your favorite Pokemon", "Your favorite anime character", "Freddy Krueger", "Captain America", "Goku", "Bambi", "Wolverine", "Black Panther", "Shrek", "Sonic the Hedgehog", "Mario", "your favorite video games character", "Naruto Uzumaki", "Monkey D. Luffy", "King Kong", "Godzilla", "Harry Potter", "Gollum", "Daenerys Targaryen", "Jessica Rabbit", "Mystique", "Snow White", "Lara Croft", "Ariel", "Neytiri from Avatar", "Gally from Gunnm", "Tinker Bell", "Beatrix Kiddo"],
    "adj" : ["baby one", "in cute", "chibi", "playing video games", "playing", "happy", "with a pokemon type", "with scales", "with an armor", "zombi", "fighting", "training at gym", "eating a burger", "drinking a smoothie", "in an apocalyptic world", "reading", "with a long beard", "with an other skin color", "gender swiped"]
}

const realCharacters = {
    "names" : ["Napoleon Bonaparte", "Pewdiepie", "Friedrich Nietzch", "Pokiman", "Sun Tzu", "Jackie Chan", "The Rock", "Vin Diesel", "The Queen Elizabeth 2", "Abraham Lincoln", "Marilyn Monroe", "Nelson Mandela", "Bill Gates", "Mahatma Gandhi", "Charles Darwin", "Elvis Presley", "Albert Einstein", "the Pope", "Leonardo da Vinci", "Vincent Van Gogh", "Will Smith", "Dalai Lama", "J.K. Rowling", "Pele", "Joseph Stalin", "Greta Thunberg", "Pablo Picasso", "Coco Chanel", "Charles de Gaulle", "Gengis Khan", "Cleopatra", "Steve Jobs", "Mao Zedong", "Usain Bolt", "J.R.R. Tolkien", "Simon Bolivar", "Marie Antoinette", "Marie Curie", "Stephen Hawking", "Shakira", "Tom Cruise", "Brad Pitt", "Mahatma Gandhi", "Elon Musk", "Princess Diana", "Joan of Arc", "Madonna", "Hayao Miyazaki", "Confucius", "Jet Li"],
    "adj" : ["baby one", "in cute", "chibi", "playing video games", "playing", "happy", "with a pokemon type", "with scales", "with an armor", "zombi", "fighting", "training at gym", "eating a burger", "drinking a smoothie", "in an apocalyptic world", "reading", "with a long beard", "with an other skin color", "gender swiped"]
}

const job = {
    "names" : [ "a firefighter", "a superhero", "an electrician", "a plumber", "a teacher", "a scientist", "a youtuber", "a zoo keeper", "an engineer", "a commercial", "a lawyer", "a police officer", "a painter", "a pokemon trainer", "a clown", "a technician", "a fisherman", "a recruiter", "an archeologist", "a doctor", "a surgeon", "a nurse", "an investigator", "a president", "a warrior", "a magician", "a knight", "a king/queen", "an emperor", "a programmer", "a streamer", "a CEO", "a photographer", "an astronaut", "a boxer", "a bodybuilder", "a sniper", "a teacher", "a bus driver", "a model", "a programmer"],
    "adj" : ["eating", "drinking", "working", "sleeping at work", "laughing", "angry", "crying", "happy", "dancing", "playing video games", "playing board games", "crazy", "playing chess", ]
}

const someone = {
    "fictionnal characters" : ficCharacters,
    "real characters" : realCharacters,
    "jobs" : job
}


// BUILDING OBJECTS

const monuments ={
    "names" : ["The Great Wall of China", "The Eiffel Tower", "The kheops pyramid", "The World Trade Center", "The Colosseum","The Mausoleum of Halicarnassus", "The Forbidden City", "The Etemenanki", "The Ruhr Valley", "The temple of Artemis", "The Mahabodhi Temple", "Petra", "Chichen Itza", "The Mont St. Michel", "The St. Basil's cathedral", "The Oxford University", "Big Ben", "The Eiffel Tower", "The Cristo Redentor", "The Hagia Sophia", "The Alhambra", "Angkor Wat", "The Potala Palace", "The Venetian Arsenal", "The Orszaghaz", "The Amundsen-Scott Research Station", "The Terracotta Army", "The Meenakshi Temple", "The University of Sankoré", "The Great Zimbabwe", "The Casa de Contratación", "The Taj Mahal", "The Bolshoi Theatre", "The Statue of Zeus", "The Apadana", "The colossus of Rhodes", "The Fushimi Inari", "The Kotoku-In", "The Statue of Liberty", "Broadway", "The Torre de Belem", "The Hanging Gardens", "The Hermitage", "The Great Bath", "Stonehenge", "The Great Lighthouse", "The Machu Picchu", "The Golden Gate Bridge", "Tower Bridge", "The Sydney Opera House"],
    "adj" : ["flying in the sky", "on an other planet", "underwater", "burning", "with a different color", "in a futuristic way", "with middle-age architecture", "with an architecture from an other continent", "abandonned with a lot of plants", "with robots", "under the snow", "behind a fog", "on an island", "at the top of a mountain", "near a lake", "near a volcano"]
}

const religiousBuilding = {
    "names" : ["a crypt", "a mosque", "a church", "a shinto shrine", "a greek temple", "a roman temple", "a buddhist temple", "a gothic cathedral", "a baroque cathedral", "a basilica", "a chapel", "a monastery", "a convent", " the tori of a shinto shrine", "a stupa", "a nordic church", "a gurdwara", "a wat", "a mausoleum", "a minaret", "a pagoda"],
    "adj" : ["in gold", "underwater", "erosed by time", "flying in the sky", "on an other planet", "underwater", "burning", "with a different color", "in a futuristic way", "with middle-age architecture", "with an architecture from an other continent", "abandonned with a lot of plants", "with robots", "under the snow", "behind a fog", "on an island", "at the top of a mountain", "near a lake", "near a volcano"]
}

const publicBuilding = {
    "names" : ["a school", "hot springs", "a bank", "a supermarket", "an aqueduct", "an amphitheater", "an arena", "a bridge", "a bunker", "a city hall", "a laboratory", "a concert hall", "a college", "catacomb", "a factory", "a fortress", "a farmhouse", "a greenhouse", "a forge", "a gallery", "a gym", "a gas station", "a gymnasium", "a hospital", "a lighthouse", "a museum", "a shelter", "a windmill", "a market", "a power plant", "an opera house", "an observatory", "a prison", "a palace", "a restaurant", "a school", "a skyscraper", "a stadium", "a tower", "an university"],
    "adj" : ["in gold", "in fire", "underwater", "erosed by time", "flying in the sky", "on an other planet", "underwater", "burning", "with a different color", "in a futuristic way", "with middle-age architecture", "with an architecture from an other continent", "abandonned with a lot of plants", "with robots", "under the snow", "behind a fog", "on an island", "at the top of a mountain", "near a lake", "near a volcano"]
}

const privateBuilding = {
    "names" : ["a yurt", "a villa", "a treehouse", "a tudor house", "a tent", "a tipi", "a ranch", "a pool house", "a mansion", "a manor", "a log cabin", "an igloo", "a hut", "your home", "a cabana", "a castle", "a cottage", "a chalet", "a bungalow", "a bunkhouse", "a boathouse", "an apartment building", "a duplex", "a triplex", "a kitchen", "a bedroom", "a restroom", "a bathroom", "a living room"],
    "adj" : ["in gold", "in fire", "underwater", "erosed by time", "flying in the sky", "on an other planet", "underwater", "burning", "with a different color", "in a futuristic way", "with middle-age architecture", "with an architecture from an other continent", "abandonned with a lot of plants", "with robots", "under the snow", "behind a fog", "on an island", "at the top of a mountain", "near a lake", "near a volcano"]
}

const buildings = {
    "monuments" : monuments,
    "religious buildings" : religiousBuilding,
    "public buildings" : publicBuilding,
    "private buildings" : privateBuilding
}


// OBJECTS OBJECTS


const furnitures = {
    "names" : ["a bed", "a lamp", "a chandeler", "a cabinet", "a desk", "a chair", "a bed table", "a bench", "a couch", "a throne", "a wing chair", "a chest", "a commode", "a clock", "a bookshelf", "a sink", "curtains", "a pillow", "a carpet", "a barbecue", "a stool", "toilets", "a bathtube", "a mirror", "a vase", "a flower pot", "a dest lamp", "a banker lamp", "a buffet lamp", "a lantern", "a torch", "a candle", "a blackboard", "a gaming chair"],
    "adj" : ["in wood", "in metal", "in white", "cute", "gothic", "in dark wood", "in white wood", "with animal patterns", "with jewels", "in gold", "a giant one", "a very small one"]
}

const tools = {
    "names" : ["a fork", "a hammer", "a screwdriver", "an axe", "a saw", "scissors", "a drill", "a chainsaw", "a circular saw", "a shovel", "a trowel", "a lawnmower", "shears", "an anvil", "a toolbox", "a sharpening stone", "a stepladder", "scissors", "a saw", "a wrench", "a bolt", "a knife", "a spoon", "a pan", "a saucepan", "a pen", "a pencil", "an eraser", "a drawing table", "a computer", "a laptop"],
    "adj" : ["in giant", "in small", "broken", "over-engineered", "with futur technology", "abandoned in nature", "in a robotic arm", "with jewels", "producing light", "in wood", "in diamond"]
}

const transports = {
    "names" : ["a train", "a car", "a plane", "the Hyperloop", "a ship", "a boat", "a pirate ship", "a bycicle", "a skateboard", "a steam train", "a subway", "a cabriolet", "a truck", "a van", "a campervan", "a passenger airliner", "an airship", "a gilder plane", "a biplane", "a helicopter", "a jet", "a monoplane", "a seaplane", "a gyroplane", "an UAV", "a hot air balloon", "a space rocket", "a bus", "a cable car", "a bike", "a sportbike", "a chopper", "a scooter", "a cruise", "a brig", "a yacht", "a container ship", "a tanker", "a tank", "a jeep", "a jet ski boat", "a kayak", "a bycicle", "a trawler", "a sailboat", "a wooden raft", "a cabin cruiser", "a caravelt", "a submarine", "a carrack"],
    "adj" : ["in giant", "in small", "broken", "over-engineered", "with futur technology", "abandoned in nature", "with jewels", "in wood", "in diamond", "in the sky", "carrying a lot of person", "transparent"]
}

const other = {
    "names" : ["a plush", "a pillow", "a gun", "a sword", "a grenade", "a nuke", "a shuriken", "a boomerang", "a spear", "a chessboard", "a game", "a CD", "a mixing table", "an USB key", "a trident", "a violin", "a crossbow", "a nunchucks", "a parasol", "a bow", "a surgeon mask", "a mask", "a flute", "a piano", "drums", "a guitar", "an erhu", "a banjo", "a clarinet", "a french horn", "a trumpet", "a trombone", "a marimba", "a pan flute", "an ocarina", "a kalimba", "a game board", "a dice", "a RPG dice"],
    "adj" : ["in wood", "in metal", "in white", "cute", "gothic", "in dark wood", "in white wood", "with animal patterns", "with jewels", "in gold", "a giant one", "a very small one", "with future technology"]
}

const clothes = {
    "names" : ["a T-shirt", "a sweater", "a jacket", "a coat", "jeans", "socks", "shorts", "a tracksuit", "a vest", "a pajamas", "shoes", "boots", "a raincoat", "a tanktop", "a swimsuit", "a skirt", "a dress", "heels", "a blouse", "a bra", "panties", "a suit", "a shirt", "a tie", "a bow-tie", "a wool hat", "a scarf", "glasses", "sunglasses", "a bagpack", "a bag", "earrings", "a bracelet", "a belt", "a handkerchief", "a neckglace", "a purse", "a wallet", "a wig","a hat", "a cap", "a  ring", "a watch", "a shawl"],
    "adj" : ["with fur", "in leather", "red", "japanese fashion stylé", "african fashion style", "american fashion style", "european fashion style", "central asia style", "arabic style", "indian style", "chinese style", "mexican style", "gothic", "vintage", "casual", "grunge", "bohemian", "exotic"]
}

const objects = {
    "furnitures" : furnitures,
    "tools" : tools,
    "transports" : transports,
    "other" : other,
    "clothes" : clothes
};


// LANDSCAPE OBJECTS

const realWonders = {
    "names" : ["The Yellowstone Park", "The Sahara", "The Everest", "The Great Barrier Reef", "Deep-Sea Hydrothermal vents", "The Lake Baikal", "The pink lake", "The Iguazu Falls", "Puerto Princesa Underground River", "The Jellyfish Lake", "The Chocolate Hills", "Etretat", "The Crater Lake", "The Delicate Arch", "The Mount Fuji", "The Giant's Causeway", "The Hạ Long Bay", "The Gobustan", "Ik Kil", "The Lysefjord", "The Matterhorn", "The Mount Kilimanjaro", "The Mount Roraima", "Pamukkale", "The Chocolate Hills", "The Pantanal", "Torres del Paine National Park", "Yellowston Park", "Tsingy de Bemaraha", "Yosemite National Park", "Zhangye National Geopark"],
    "adj" : ["under the snow", "amplified", "surrounded by cities", "under the rain", "on an other planet", "with visitors", "in a fantasy world", "in an apocalyptic world"]
}
const livingPlaces = {
    "names" : ["a city", "a village", "a lonely house", "a megalopolis", "a japanese village", "a japanese city", "a european village", "a french city", "a swiss village", "a chinese city", "a chinese village", "an african city", "an african village", "an egyptian city", "a nomadic camp", "a viking village", "an indian city"],
    "adj" : ["during the war", "with a lot of plants", "burning", "abandonned", "inhabited by monsters", "inhabited by elves", "at the top of a mountain", "near a volcano", "on an island", "near a lake", "in the desert", "under the snow", "under the rain", "in a futuristic way", "on the sea", "on a flying island", "underground"]
}
const biomes = {
    "names" : ["a tropical rainforest", "a temperate giant rainforest", "a montane rain forest", "a taiga", "a woodland", "a shrubland", "a savanna", "a temperate grassland", "an alpine grassland", "a tundra", "a tropical desert", "a desert", "an arctic-alpine desert", "a bog", "a swamp", "a mangrove swamp", "a wetland", "an ocean", "a warm ocean", "an arctic ocean", "an abyssal ocean", "a salt marsh", "a pack ice", "a kelp forest", "a barrier reef"],
    "adj" : ["under the rain", "under the snow", "with the moonlight", "on an other planet", "polluted", "with a lot of animals", "surrounding a city", "surrounding a village", "with an abandoned city", "with an abandoned village", "in a fantasy world"],
}

const landscape = {
    "real wonders" : realWonders,
    "living places" : livingPlaces,
    "biomes" : biomes
}


// FOOD OBJECTS

const drink = {
    "names" : ["coke", "lemonade", "water", "vodka", "red wine", "white wine", "whiskey", "milk", "cognac", "blond beer", "rum", "sake", "liquor", "brown beer", "red beer", "orange juice", "apple juice", "multi fruits juice", "smoothie", "blueberry smoothie", "strawberry smoothie", "black tea", "green tea", "white tea", "oolong tea", "coffee"],
    "adj" : ["with ice", "in a cute glass", "in a cup tea", "with ice and lemon", "in a can", "in a glass bottle", "in a cocktail glass"]
}

const meal = {
    "names" : ["a burger", "a ramen", "a pizza", "pasta", "noodles", "soba", "a breakfast", "a brunch", "a lunch", "a dinner", "a kebab", "a pie", "stew", "a sausage", "a poutine", "a fruits salad", "a cake", "a brownie", "a flan", "a cheese", "a camembert", "a panna cotta", "a vegetables mix", "ice cream", "a pastry", "a mafe chicken", "a thai curry", "a japanese curry", "a tacos", "a burritos", "french fries"],
    "adj" : ["overcooked", "not cooked", "vegan", "with spicies", "in an indian way", "in a french way", "in a chinese way", "in an italian way", "in a japanese way", "in a banquet", "in a lunchbox", "in a bento", "on a japanese plate", "on a chinese plate", "on a french plate", "on an arabic plate", "in a glass", "on a banana leaf", "with spices on it", "with cheese on it", "looking like an other meal", "looking like a vegetable", "looking like an object", "becoming alive"]
}

const food= {
    "fruits and vegetables" : fruitsVegetables,
    "meals" : meal,
    "drinks" : drink
}

const style = ['a manga anime', 'a cubic', 'a cartoon', 'a caricature', "a black and white", "a comics", "pointillism", "a scientific illustrations", "a geometric", "a single color", "a stippling", "a pixelated", "a line drawing", "a doodling", "a hyperrealism", "an anamorphic", "a pop-art", "a street art"];







function Home() {
    const [random_draw, setrandom_draw] = useState('Click on generate!');
    const [topics, setTopics] = useState([]);
    const topicsCollectionRef = collection(db, "Prev_challenges");


    const [addStyle, setAddStyle] = useState(0);
    const [addAdj, setAddAdj] = useState(0);



    const [criteriaSelected, setCriteriaSelected] = useState("");



    const [detail, setDetail] = useState("");
    const [detailsList, setDetailsList] = useState([]);
    const [currentObject, setCurrentObject] = useState();
                            
    useEffect(() => {
        const getTopics = async()=>{
            const q = query(topicsCollectionRef, orderBy('createdAt', "desc"), limit(1))
            const data = await getDocs(q);
            setTopics(data.docs.map((doc)=>({...doc.data(), id:doc.id})));
        }
        getTopics();
    }, []);

    function NewDrawing(props){
        let valueForCriteria;
        if(criteriaSelected === ""){
            const chooseARandomTopic = ["sAsS", "monsterMix", "vegetal", "object", "someone", "animal", "building", "landscape", "food"];
            valueForCriteria = chooseARandomTopic[Math.floor(Math.random()*chooseARandomTopic.length)];
        }else{
            valueForCriteria =criteriaSelected;
        }
        if(valueForCriteria === "sAsS"){
            return asTopic();
        }
        if(valueForCriteria === "monsterMix"){
            return monsterMix();
        }
        if(valueForCriteria === "vegetal"){
            return randomTopic(vegetal);
        };
        if(valueForCriteria === "object"){
            return randomTopic(objects);
        };
        if(valueForCriteria === "someone"){
            return randomTopic(someone);
        }
        if(valueForCriteria === "animal"){
            return randomTopic(animals);
        }
        if(valueForCriteria === "building"){
            return randomTopic(buildings);
        }
        if(valueForCriteria === "landscape"){
            return randomTopic(landscape);
        }
        if(valueForCriteria === "food"){
            return randomTopic(food);
        }
    };

    function selectedCriterias(criteria){
        if(criteria === criteriaSelected){
            setCriteriaSelected("")
            setDetailsList([]);
            setCurrentObject();
        }else{
            setCriteriaSelected(criteria);
            if(criteria === 'vegetal'){
                setDetailsList(Object.keys(vegetal));
                setCurrentObject(vegetal);
            }
            else if(criteria === 'animal'){
                setDetailsList(Object.keys(animals));
                setCurrentObject(animals);
            }
            else if(criteria === 'building'){
                setDetailsList(Object.keys(buildings));
                setCurrentObject(buildings);
            }
            else if(criteria === 'someone'){
                setDetailsList(Object.keys(someone));
                setCurrentObject(someone);
            }
            else if(criteria === 'object'){
                setDetailsList(Object.keys(objects));
                setCurrentObject(objects);
            }
            else if(criteria === 'landscape'){
                setDetailsList(Object.keys(landscape));
                setCurrentObject(landscape);
            }
            else if(criteria === 'food'){
                setDetailsList(Object.keys(food));
                setCurrentObject(food);
            }else{
                setDetailsList([]);
            }
        }
        setDetail('');
    }


    function chooseAnIdea(numChosen, topicObjects){
        for(let property in topicObjects){
            if(numChosen < topicObjects[property].names.length){
                if(addAdj === 1){
                    return(topicObjects[property].names[numChosen] + " (" + topicObjects[property].adj[Math.floor(Math.random()*topicObjects[property].adj.length)] + ")")
                }else{
                    return(topicObjects[property].names[numChosen])
                }
            }
            numChosen = numChosen - topicObjects[property].names.length;
        }
    }



    function randomTopic(objectSelected){
        if (detail === ""){
            let totalLength = 0;
            for(let i in objectSelected){
                totalLength = totalLength + objectSelected[i].names.length;
            }
            let numChosen = Math.floor(Math.random()*totalLength);
            return chooseAnIdea(numChosen, objectSelected)
            
        }else{
            let numChosen = Math.floor(Math.random()*objectSelected[detail].names.length);
            if(addAdj === 1){
                return( objectSelected[detail].names[numChosen] + " (" + objectSelected[detail].adj[Math.floor(Math.random()*objectSelected[detail].adj.length)] + ")");
            }else{
                return objectSelected[detail].names[numChosen]
            }
            
        }
    }

    function monsterMix(){
        return("A mix with " + randomTopic(animals)+" and "+randomTopic(animals));

    }

    function asTopic(){
        const listTopics = [objects, someone, animals, vegetal];
        const lenListTopics = listTopics.length;
        return(randomTopic(listTopics[Math.floor(Math.random()*lenListTopics)])+" as "+randomTopic(listTopics[Math.floor(Math.random()*lenListTopics)]));
    }

    function chooseStyle(){
        if(addStyle === 0){
            setrandom_draw(NewDrawing());
        }else{
            setrandom_draw(NewDrawing()+ " in " + style[Math.floor(Math.random()*style.length)] + " style!");
        }
    }

    var d = new Date();
    d.setDate(d.getDate() + ((7 - d.getDay()) % 7 + 1) % 7 + 7);


    return (
        <div className="App">
            <Helmet>
                <title>Drawfinity</title>
            </Helmet>
            <Header />
            <div className="section">
                {topics.length !== 0 &&
                    <div>
                        <h2>The challenge of the week</h2>
                        <div className="topic-idea-container">
                            <h3 className="topic-idea">{topics[0].topic}</h3>
                        </div>
                        <p>Follow what the other artists did on the following social medias with the <b>#Drawfinity{topics[0].day}</b></p>
                        <div>
                            <h3><a href ={ "https://www.instagram.com/explore/tags/Drawfinity" + topics[0].day} className="icon-style"><Icon icon="il:instagram"/></a>
                            <a href ={"https://twitter.com/hashtag/Drawfinity"+ topics[0].day+"?src=hashtag_click"} className="icon-style"><Icon icon="il:twitter"/></a>
                            <a href={"https://www.pinterest.fr/search/pins/?q=Drawfinity" + topics[0].day} className="icon-style"><Icon icon="akar-icons:pinterest-fill"/></a>
                            <a href = {"https://www.tumblr.com/tagged/Drawfinity"+ topics[0].day + "?sort=top"} className="icon-style"><Icon icon="brandico:tumblr-rect"/></a>
                            <a href = {"https://www.deviantart.com/tag/Drawfinity" +  topics[0].day} className="icon-style"><Icon icon="cib:deviantart" /></a>
                            </h3>
                        </div>
                    
                    </div>
                }
                <div className="previous_challenges_div">
                    <a className="previous_challenges buttons" href="/list">Check the previous challenges here!</a>
                </div>
            </div>
            <div className="section" id= "generate-return">
                <div>
                    <h2>What can you draw :</h2>
                    <button  className="buttons generate" onClick={() => chooseStyle()}>
                        <p>Generate</p>
                    </button>
                    <div className="topic-idea-container">
                        <h3 className="topic-idea">{random_draw.charAt(0).toUpperCase() + random_draw.slice(1)} </h3>
                    </div>
                </div>
                <div>
                    <h3>You want something more specific? Select the criterias below :</h3>
                </div>
            </div>
            <div>
                <div className="container-buttons-criterias">
                    <div className={criteriaSelected !== "sAsS" ? "buttons-criterias-border gradient-criterias" : "buttons-criterias-border gradient-criterias shadow-criterias-selected"} onClick={() => selectedCriterias("sAsS")}>
                        <div className={criteriaSelected !== "sAsS"  ? "buttons-criterias" : "buttons-criterias-selected"} onClick={() => selectedCriterias("sAsS")}>
                            <p className="criteria">Something as something</p>
                            <p className="criteria-example">ex : Mickey as a cactus</p>
                        </div>
                    </div>
                    <div className={criteriaSelected !== "landscape" ? "buttons-criterias-border gradient-criterias" : "buttons-criterias-border gradient-criterias shadow-criterias-selected"} onClick={() => selectedCriterias("landscape")}>
                        <div className={criteriaSelected !== "landscape"  ? "buttons-criterias" : "buttons-criterias-selected"} onClick={() => selectedCriterias("landscape")}>
                            <p className="criteria">A landscape</p>
                            <p className="criteria-example">ex : a montain with red sky</p>
                        </div>
                    </div>
                    <div className={criteriaSelected !== "monsterMix" ? "buttons-criterias-border gradient-criterias" : "buttons-criterias-border gradient-criterias shadow-criterias-selected"} onClick={() => selectedCriterias("monsterMix")}>
                        <div className={criteriaSelected !== "monsterMix"  ? "buttons-criterias" : "buttons-criterias-selected"} onClick={() => selectedCriterias("monsterMix")}>
                            <p className="criteria">Monster mix</p>
                            <p className="criteria-example">ex : a meadow</p>
                        </div>
                    </div>
                    <div className={criteriaSelected !== "food" ? "buttons-criterias-border gradient-criterias" : "buttons-criterias-border gradient-criterias shadow-criterias-selected"} onClick={() => selectedCriterias("food")}>
                        <div className={criteriaSelected !== "food"  ? "buttons-criterias" : "buttons-criterias-selected"} onClick={() => selectedCriterias("food")}>
                            <p className="criteria">Food</p>
                            <p className="criteria-example">ex : ramen</p>
                        </div>
                    </div>

                    <div className={criteriaSelected !== "object" ? "buttons-criterias-border gradient-criterias" : "buttons-criterias-border gradient-criterias shadow-criterias-selected"} onClick={() => selectedCriterias("object")}>
                        <div className={criteriaSelected !== "object"  ? "buttons-criterias" : "buttons-criterias-selected"} onClick={() => selectedCriterias("object")}>
                            <p className="criteria">An object</p>
                            <p className="criteria-example">ex : a pen</p>
                        </div>
                    </div>
                    <div className={criteriaSelected !== "someone" ? "buttons-criterias-border gradient-criterias" : "buttons-criterias-border gradient-criterias shadow-criterias-selected"} onClick={() => selectedCriterias("someone")}>
                        <div className={criteriaSelected !== "someone"  ? "buttons-criterias" : "buttons-criterias-selected"} onClick={() => selectedCriterias("someone")}>
                            <p className="criteria">Someone</p>
                            <p className="criteria-example">ex : Harry Potter</p>
                        </div>
                    </div>
                    <div className={criteriaSelected !== "vegetal" ? "buttons-criterias-border gradient-criterias" : "buttons-criterias-border gradient-criterias shadow-criterias-selected"} onClick={() => selectedCriterias("vegetal")}>
                        <div className={criteriaSelected !== "vegetal"  ? "buttons-criterias" : "buttons-criterias-selected"} onClick={() => selectedCriterias("vegetal")}>
                            <p className="criteria">A vegetal</p>
                            <p className="criteria-example">ex : a baobab</p>
                        </div>
                    </div>
                    <div className={criteriaSelected !== "animal" ? "buttons-criterias-border gradient-criterias" : "buttons-criterias-border gradient-criterias shadow-criterias-selected"} onClick={() => selectedCriterias("animal")}>
                        <div className={criteriaSelected !== "animal"  ? "buttons-criterias" : "buttons-criterias-selected"} onClick={() => selectedCriterias("animal")}>
                            <p className="criteria">An animal</p>
                            <p className="criteria-example">ex : a seal</p>
                        </div>
                    </div>
                    <div className={criteriaSelected !== "building" ? "buttons-criterias-border gradient-criterias" : "buttons-criterias-border gradient-criterias shadow-criterias-selected"} onClick={() => selectedCriterias("building")}>
                        <div className={criteriaSelected !== "building"  ? "buttons-criterias" : "buttons-criterias-selected"} onClick={() => selectedCriterias("building")}>
                            <p className="criteria">A building</p>
                            <p className="criteria-example">ex : The oracle</p>
                        </div>
                    </div>
                </div>
            </div>
            
            { detailsList.length !== 0 &&
                <div >
                    <h3>You want to be more precised?</h3>
                    <div className="container-buttons-criterias">
                        {   detailsList !== undefined &&
                            detailsList.map((value) =>{ return(
                            <div className={value !== detail ? "buttons-criterias-border gradient-details" : "buttons-criterias-border gradient-details shadow-details-selected"} onClick = {()=> detail === value ? setDetail("") : setDetail(value)}>
                                <div className={value !== detail  ? "buttons-criterias" : "buttons-criterias-selected"} onClick = {()=> detail === value ? setDetail("") : setDetail(value)}>
                                    <p className="criteria">{value.charAt(0).toUpperCase() + value.slice(1)}</p>
                                    <p className="criteria-example">ex : {currentObject[value].names[0]}</p>
                                </div>
                            </div>);
                            })
                        }
                    </div>
                </div>
            }
            <div>
                <h3>You want to add something to the topic? You can select both!</h3>
                <div className="container-buttons-criterias">
                    <div className={addStyle === 0 ? "buttons-criterias-border gradient-style" : "buttons-criterias-border gradient-style shadow-style-selected"} onClick={()=> setAddStyle(1 - addStyle)}>
                        <div className={addStyle === 0  ? "buttons-criterias" : "buttons-criterias-selected "} onClick={()=> setAddStyle(1 - addStyle)}>
                            <p className="criteria" >Add a certain style</p>
                            <p className="criteria-example">ex : Japanese style</p>
                        </div>
                    </div>
                    <div className={addAdj === 0 ? "buttons-criterias-border gradient-style" : "buttons-criterias-border gradient-style shadow-style-selected"} onClick={()=> setAddAdj(1 - addAdj)}>
                        <div className={addAdj === 0  ? "buttons-criterias" : "buttons-criterias-selected"} onClick={()=> setAddAdj(1 - addAdj)}>
                            <p className="criteria" >Add a detail</p>
                            <p className="criteria-example">ex : an apple <b>(sliced)</b></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="generate-return-div">
                <a href="#generate-return">
                    <button className="buttons generate2" onClick={() => chooseStyle()}>
                        <p>Generate<br/>
                        <Icon icon="typcn:arrow-up-thick" />
                        </p>
                    </button>
                </a>
            </div>
            <Footer />
        </div>
    );
}

export default Home;
