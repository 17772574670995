import React, { useEffect, useState } from "react";
import {collection, getDocs, orderBy, query} from "firebase/firestore";

import Footer from "./Footer"
import Header from "./Header"
import {Helmet} from "react-helmet";
import { Icon } from '@iconify/react';
import ReactPaginate from 'react-paginate';
import {db} from "./firebase-config";

function Items(props) {
  return (
    
    <>
      {props.currentItems !== null &&
                    props.currentItems.map((val, index)=> 
                        <div className={'container-list color' +(index+1)%2 + '-list' }>
                            <span className="id-list">{props.topicsLen - index - props.itemOffset}</span>   
                            <span className="topic-list">{val.topic}</span>
                            <div className="left-of-table" >
                                <span className="date-list">{val.day}</span> 
                                <div className="app-list">
                                    <a href ={ "https://www.instagram.com/explore/tags/Drawfinity" + val.day}> <Icon icon="il:instagram" /></a>
                                    <a href ={"https://twitter.com/hashtag/Drawfinity" + val.day + "?src=hashtag_click"}> <Icon icon="il:twitter" /></a>
                                    <a href={"https://www.pinterest.fr/search/pins/?q=Drawfinity" + val.day}> <Icon icon="akar-icons:pinterest-fill" /></a>
                                    <a href = {"https://www.tumblr.com/tagged/Drawfinity"+  val.day + "?sort=top"}> <Icon icon="brandico:tumblr-rect" /></a>
                                    <a href = {"https://www.deviantart.com/tag/Drawfinity" +  val.day}> <Icon icon="cib:deviantart" /></a>
                                </div>
                            </div>
                        </div>
                    )}
    </>
  );
}




function List() {
    function PaginatedItems({ itemsPerPage }) {
        // We start with an empty list of items.
        const [currentItems, setCurrentItems] = useState(null);
        const [pageCount, setPageCount] = useState(0);
        // Here we use item offsets; we could also use page offsets
        // following the API or data you're working with.
        const [itemOffset, setItemOffset] = useState(0);
      
        useEffect(() => {
          // Fetch items from another resources.
          const endOffset = itemOffset + itemsPerPage;
          setCurrentItems(topics.slice(itemOffset, endOffset));
          setPageCount(Math.ceil(topicsLen / itemsPerPage));
        }, [itemOffset, itemsPerPage]);
      
        // Invoke when user click to request another page.
        const handlePageClick = (event) => {
          const newOffset = (event.selected * itemsPerPage) % topicsLen;

          setItemOffset(newOffset);
        };
        return (
          <>
            <Items currentItems={currentItems} endOffset={itemOffset + itemsPerPage} itemOffset = {itemOffset} topicsLen = {topicsLen}/>
            <div className="nav-list">
                <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="<"
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="page-link"
                pageClassName="page-item"
                previousClassName="page-item"
                previousLinkClassName="page-link prev-next"
                nextClassName="page-item"
                nextLinkClassName="page-link prev-next"
                breakLinkClassName="page-item"
                activeClassName="active-page"
                activeLinkClassName="active-page-link"
                />
            </div>
            
          </>
        );
      }





    const [topics, setTopics] = useState([]);
    const [topicsLen, setTopicsLen] = useState(0);
    const topicsCollectionRef = collection(db, "Prev_challenges")
                            
    useEffect(() => {
        const getTopics = async()=>{
            const q = query(topicsCollectionRef, orderBy('createdAt', "desc"))
            const data = await getDocs(q);
            setTopics(data.docs.map((doc)=>({...doc.data(), id:doc.id})));
            setTopicsLen(data.docs.length);
            
        }
        getTopics();        
    }, []);
    return (
        <div className="App">
            <Helmet>
                <title>Drawfinity</title>
            </Helmet>
            <Header />
            <div>
                <h2>The previous challenges</h2>
                <div className="container-list">
                    <div className="id-list">
                        <span><b>n°</b></span>
                    </div>
                    <span className="topic-list"><b>Topic</b></span>
                    <div className="left-of-table">
                        <div className="date-list">
                            <span><b>Date</b><b className="display-none"> & </b></span>
                        </div>
                        <div  className="app-list-title">
                            <span><b>Apps</b></span>
                        </div>

                    </div>
                </div>
                <hr className="hr-list"/>
                <PaginatedItems itemsPerPage={5}/>
            </div>
            <Footer />
        </div>
    );
}

export default List;
