import { initializeApp } from "firebase/app";
import {getFirestore} from "@firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyC4r1rPs2vMA32Wwppc0bpWipb5RAxbllo",
    authDomain: "drawfinity-9fec2.firebaseapp.com",
    projectId: "drawfinity-9fec2",
    storageBucket: "drawfinity-9fec2.appspot.com",
    messagingSenderId: "521991869334",
    appId: "1:521991869334:web:1fcc2a3a6e0251f55983b7",
    measurementId: "G-LFV5LS91N0"
  };

  const app = initializeApp(firebaseConfig);

  export const db = getFirestore(app);