import Home from './components/Home'
import List from './components/List'
import './App.css';
import { BrowserRouter, Routes, Route, Redirect } from "react-router-dom";

require('dotenv').config();


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element = {<Home/>}/>
        <Route exact path='/list' element = {<List/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;