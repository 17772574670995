import React from "react";


function Footer(props) {
  const year = new Date().getFullYear();
  return (
      <footer>
          <hr/>
          <div className="copiright-footer">
            <span >Copyright ⓒ {year}</span>
          </div>
      </footer>
  );
};

export default Footer;