import React from "react";

function Header(props) {
  return (
      <header>
        <nav class=" navbar fixed-top fixed-top navbar-expand-lg navbar-light bg-light">
          <div className="h1-div-size">
            <h1><b><a href="/">Drawfinity</a></b></h1>
          </div>
        </nav>
      </header>
  );
};

export default Header;